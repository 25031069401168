import React, { useState } from 'react';
import axios from 'axios';
import './Chat.css';

function Chat({ token }) {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null); // Stato per gestire i file
  const [chat, setChat] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Stato per gestire il caricamento

  // Funzione per inviare il messaggio e/o il file
  const handleSend = async () => {
    if (!message.trim() && !file) return; // Controlla che ci sia un messaggio o un file da inviare

    setIsLoading(true); // Imposta il caricamento a true

    // Aggiungi il messaggio utente alla chat
    if (message.trim()) {
      const userMessage = message;
      setMessage(''); // Resetta il messaggio
      setChat((prevChat) => [...prevChat, { sender: 'user', text: userMessage }]);
    }

    const formData = new FormData();
    formData.append('message', message); // Aggiungi il messaggio
    if (file) {
      formData.append('file', file); // Aggiungi il file al formData
    }

    try {
      console.log("Sending message and file to backend with token: ", token);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat`,
        formData,
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
      );

      console.log("Response from backend: ", response.data);
      setChat((prevChat) => [...prevChat, { sender: 'bot', text: response.data.reply }]);
      setFile(null); // Resetta il file dopo l'invio
    } catch (error) {
      console.error('Errore:', error);
      alert(`Errore: ${error.message}`);
    } finally {
      setIsLoading(false); // Fine del caricamento
    }
  };

  // Funzione per inviare il messaggio quando si preme "Enter"
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  // Gestisci il cambio di file
  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Imposta il file selezionato nello stato
  };

  return (
    <div className="chat-container">
      <div className="chat-window">
        {chat.map((msg, idx) => (
          <div key={idx} className={`chat-message ${msg.sender}`}>
            <p>{msg.text}</p>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          placeholder="Scrivi un messaggio..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress} // Invio con "Enter"
          disabled={isLoading} // Disabilita durante il caricamento
        />
        <input 
          type="file" 
          onChange={handleFileChange} 
          disabled={isLoading} // Disabilita durante il caricamento
        /> {/* Campo per caricare il file */}
        <button onClick={handleSend} disabled={isLoading}>
          {isLoading ? 'Inviando...' : 'Invia'}
        </button>
      </div>
    </div>
  );
}

export default Chat;
