import React, { useState } from 'react';
import Login from './components/Login';
import Chat from './components/Chat';
import Register from './components/Register';
//import logo from './logo.png';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [showRegister, setShowRegister] = useState(false); // Aggiungi lo stato per la registrazione

  const handleSetToken = (token) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  const toggleRegister = () => {
    setShowRegister(!showRegister); // Alterna tra login e registrazione
  };

  if (token) {
    return <Chat token={token} />;
  }

  return showRegister ? (
    <Register toggleRegister={toggleRegister} />
  ) : (
    <Login setToken={handleSetToken} toggleRegister={toggleRegister} />
  );
}

export default App;
