import React, { useState } from 'react';
import axios from 'axios';

const Register = ({ toggleRegister }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Stato per gestire il caricamento

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Avvia il caricamento
    setMessage(''); // Resetta eventuali messaggi precedenti

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/register`,
        { username, password }
      );
      setMessage(<> 

	Registrazione avvenuta con successo.<br />
	 Attendi l'autorizzazione dell'amministratore per poter accedere. <br/>
	Aspetta qualche secondo e ritornerai alla pagina di login
	</>
	); // Messaggio personalizzato

      // Opzionalmente, puoi reindirizzare l'utente al login automaticamente dopo 3 secondi:
      setTimeout(() => {
        toggleRegister(); // Cambia vista per mostrare la pagina di login
      }, 9000);
    } catch (error) {
      if (error.response) {
        // Il server ha risposto con uno status diverso da 2xx
        console.error('Errore nella risposta:', error.response.data);
        setMessage(error.response.data.message || 'Errore durante la registrazione');
      } else if (error.request) {
        // La richiesta è stata fatta ma non è stata ricevuta una risposta
        console.error('Nessuna risposta ricevuta:', error.request);
        setMessage('Nessuna risposta dal server');
      } else {
        // Qualcosa è andato storto nella configurazione della richiesta
        console.error('Errore nella richiesta:', error.message);
        setMessage('Errore nella richiesta');
      }
    } finally {
      setIsLoading(false); // Fine del caricamento
    }
  };

  return (
    <div className="register-container">
      <form onSubmit={handleRegister}>
        <h2>Registrazione</h2>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required // Aggiungi l'attributo required per la validazione
            disabled={isLoading} // Disabilita l'input durante il caricamento
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required // Aggiungi l'attributo required per la validazione
            disabled={isLoading} // Disabilita l'input durante il caricamento
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Registrazione in corso...' : 'Registrati'}
        </button>
        <p>{message}</p>
        <button type="button" onClick={toggleRegister} disabled={isLoading}>
          Hai già un account? Accedi
        </button>
      </form>
    </div>
  );
};

export default Register;
