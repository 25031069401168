import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; // Importa il file CSS per gli stili

const Login = ({ setToken, toggleRegister }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Stato per gestire il caricamento

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Avvia il caricamento
    setMessage(''); // Resetta eventuali messaggi precedenti
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
        username,
        password,
      });
      setToken(response.data.token); // Imposta il token ottenuto
    } catch (error) {
      // Gestione specifica degli errori
      if (error.response && error.response.status === 401) {
        setMessage('Credenziali non valide');
      } else if (error.response && error.response.status === 403) {
        setMessage('Utente non autorizzato');
      } else {
        setMessage('Errore nel server. Riprova più tardi.');
      }
    } finally {
      setIsLoading(false); // Fine del caricamento
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <h2>Login</h2>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={isLoading} // Disabilita durante il caricamento
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isLoading} // Disabilita durante il caricamento
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Accedendo...' : 'Accedi'}
        </button>
        <p>{message}</p>
        <button type="button" onClick={toggleRegister} disabled={isLoading}>
          Non hai un account? Registrati
        </button> {/* Pulsante per passare alla registrazione */}
      </form>
    </div>
  );
};

export default Login;
